import React from "react";
import { Link } from "react-router-dom";
function HeroSection() {
  return (
    <section class="hero-section hero-section-3 ptb-100">
      <div class="circles">
        <div class="point animated-point-1"></div>
        <div class="point animated-point-2"></div>
        <div class="point animated-point-3"></div>
        <div class="point animated-point-4"></div>
        <div class="point animated-point-5"></div>
        <div class="point animated-point-6"></div>
        <div class="point animated-point-7"></div>
        <div class="point animated-point-8"></div>
        <div class="point animated-point-9"></div>
        <div class="point animated-point-10"></div>
        <div class="point animated-point-11"></div>
        <div class="point animated-point-12"></div>
        <div class="point animated-point-13"></div>
        <div class="point animated-point-14"></div>
        <div class="point animated-point-15"></div>
        <div class="point animated-point-16"></div>
        <div class="point animated-point-17"></div>
        <div class="point animated-point-18"></div>
        <div class="point animated-point-19"></div>
        <div class="point animated-point-20"></div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-7 col-lg-7">
            <div class="hero-content-left ptb-100">
              <h1 class="font-weight-bold font-w">
                İç İletişim Ve Çalışan Performans Platformu
              </h1>
              <p class="lead">
                Oyunlaştırma pratikleri ile şirket içi etkileşimi, motivasyonu,
                performansı ve çalışan bağlılığını artıran mobil platform.
              </p>
              <a
                style={{ marginRight: 10 }}
                href="/ming"
                title="Ming Nedir?"
                class="btn solid-btn"
                hreflang="tr"
              >
                Keşfet
              </a>
              {/* <a href="index.php?content=ming" title="Ming Nedir?" class="btn solid-btn" hreflang="tr">Keşfet</a> */}
              <a
                href="#contact"
                title="Demo Planlama"
                class="btn solid-btn2 page-scroll"
                id="home"
                hreflang="tr"
              >
                Demo Planla
              </a>
            </div>
          </div>
          <div class="screen-slider-content col-md-4 col-lg-4">
            <div class="hero-animation-img">
              <div class="screenshot-frame2"></div>
              <video className="video-mask resize-video" loop autoplay="" muted>
                <source src="/img/video_cropped_1.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/img/hero_bg_shape_2_svg.svg"
        width="454"
        height="341"
        class="shape-image"
        alt="İç İletişim Ve Çalışan Performans Platform"
      />
    </section>
  );
}

export default HeroSection;
