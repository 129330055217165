import React from "react";

function AboutUs() {
    return (
        <section class="about-us ptb-100 background-shape-img">
            <div class="circles">
                <div class="point animated-point-1"></div>
                <div class="point animated-point-2"></div>
                <div class="point animated-point-3"></div>
                <div class="point animated-point-4"></div>
                <div class="point animated-point-5"></div>
                <div class="point animated-point-6"></div>
                <div class="point animated-point-7"></div>
                <div class="point animated-point-8"></div>
                <div class="point animated-point-9"></div>
                <div class="point animated-point-10"></div>
                <div class="point animated-point-11"></div>
                <div class="point animated-point-12"></div>
                <div class="point animated-point-13"></div>
                <div class="point animated-point-14"></div>
                <div class="point animated-point-15"></div>
                <div class="point animated-point-16"></div>
                <div class="point animated-point-17"></div>
                <div class="point animated-point-18"></div>
                <div class="point animated-point-19"></div>
                <div class="point animated-point-20"></div>
            </div>
            <div class="container" id="benefits">
                <div class="row align-items-center justify-content-between">
                    <div class="col-md-7">
                        <div class="about-content-left section-heading pd-t font-card">
                            <h2> Çalışanlarınızla Android, iOS veya Huawei Cihazlar
                                ile her yerde ve her anda iletişim halinde kalın
                            </h2>
                            <div class="single-feature mb-4 mt-5">
                                <div class="icon-box-wrap d-flex align-items-center mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/image_icon_5.webp" width="512" height="512" alt="E-posta gerektirmeden şirket içi çok yönlü ve anlık iletişim" class="img-fluid ti-face-smile"
                                            style={{ marginLeft: "5px" }} />
                                    </div>
                                    <p class="mb-0">E-posta gerektirmeden şirket içi çok yönlü ve anlık iletişim</p>
                                </div>
                            </div>
                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/percentage.webp" width="512" height="512" alt="Verimli İK bütçe yönetimi" class="img-fluid" />
                                    </div>
                                    <p class="mb-0">Verimli İK bütçe yönetimi</p>
                                </div>
                                <p></p>
                            </div>
                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/image_icon_3.webp" width="44" height="43" alt="60 değerlendirme ile çalışan performans takibi ve raporlanması" class="img-fluid" />
                                    </div>
                                    <p class="mb-0">360 değerlendirme ile çalışan performans takibi ve raporlanması</p>
                                </div>
                                <p></p>
                            </div>
                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/mission.webp" width="512" height="512" alt="Kurumun vizyon, misyon ve değerlerine toplu entegrasyon" class="img-fluid" />
                                    </div>
                                    <p class="mb-0">Kurumun vizyon, misyon ve değerlerine toplu entegrasyon</p>
                                </div>
                                <p></p>
                            </div>
                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/video_game.webp" width="512" height="512" alt="Özelleştirilebilir bilgi yarışmaları ile sürekli gelişim" class="img-fluid" />
                                    </div>
                                    <p class="mb-0">Özelleştirilebilir bilgi yarışmaları ile sürekli gelişim </p>
                                </div>
                                <p></p>
                            </div>
                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/medal.webp" width="512" height="512" alt="Çalışanlar arası dengeli rekabet" class="img-fluid" />
                                    </div>
                                    <p class="mb-0">Çalışanlar arası dengeli rekabet
                                    </p>
                                </div>
                                <p></p>
                            </div>
                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/green_technology.webp" width="512" height="512" alt="Kaynak tüketimi takibi sürdürülebilirliğe katkı" class="img-fluid" />
                                    </div>
                                    <p class="mb-0">Kaynak tüketimi takibi sürdürülebilirliğe katkı
                                    </p>
                                </div>
                                <p></p>
                            </div>
                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <div class="mr-3 icon-box">
                                        <img src="/img/cloud.webp" width="512" height="512" alt="Kaynak tüketimi takibi sürdürülebilirliğe katkı" class="img-fluid" />
                                    </div>
                                    <p class="mb-0">Kurumsal bağ ve hafıza gelişimi / Oyunlaştırma ile iş süreçleri iyileştirilmesi
                                    </p>
                                </div>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="about-content-right resize">
                            <img src="/img/3sming.webp" width="870" height="856" alt="Kurumsal bağ ve hafıza gelişimi / Oyunlaştırma ile iş süreçleri iyileştirilmesi" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs;
