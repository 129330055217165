import React from 'react'

function News1() {
    return (
        <div class="main">

            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('/img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 class="text-white mb-0" id="news-1">Ming Eylülde Yayında !</h1>
                                <div class="custom-breadcrumb">
                                    <ol class="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php" title="anasayfa">Anasayfa</a></li>
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php#news" title="haberler">Haberler</a></li>
                                        <li class="list-inline-item breadcrumb-item active" title="ming">Ming</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-preview">
                                    {/* <!--<img src=""  alt="article" class="img-fluid"/>--> */}
                                </div>
                                <div class="post-wrapper">
                                    <div class="post-header">
                                        <h1 class="post-title">Çalışan Performans Platformunda Yeni Dönem Başlıyor </h1><br />
                                        <ul class="post-meta">
                                            <li>Eylül 1, 2022</li>
                                            <li><a href="index.php?content=news-1" title="yeni haber">Yeni</a></li>
                                        </ul>
                                    </div>
                                    <div class="post-content">
                                        <p style={{ fontWeight: "bold" }}>Merakla beklenen İç İletişim Ve Çalışan Performans Platformu Ming Eylülde sizlerle buluşuyor ! </p>
                                        <p>E-posta gerektirmeden ister masa başı ister saha çalışanı olsun tüm çalışanlarınızı platforma dahil edin.
                                            İster evden ister ofisten çalışanlarınızla sürekli iletişimde kalın, etkinlik oluşturun, duyuru paylaşın ve çalışanlarınızın sosyal
                                            iletişim ve paylaşım kurmalarına olanak sağlayın.</p>
                                        <blockquote class="blockquote">
                                            <p>Takımın gücü her bir üyedir. Her üyenin gücü ekiptir. </p>
                                            <footer class="blockquote-footer">Phil Jackson</footer>
                                        </blockquote>
                                        <p>Oyunlaştırma artık dünyanın her yerindeki büyük şirketlerin arzu edilen sonuçlara ulaşmak için kullandığı en verimli ve etkili araçlardan biri.</p>

                                        Oyunlaştırma; sosyalleşme, gelişmeye teşvik, geri bildirim, eğlence ve iş birliği gibi mekanizmalara sahiptir. Çoğu insan, doğal olarak, yaptıkları görevlerin sonucunda keyif almak veya ödüllendirilmek ister. Bir ödül sisteminin uygulandığı iş yerlerinde katılım ve motivasyon da doğal olarak artar. Mesela, çalışanlar diğer departmanlarla iş birliği
                                        yaptıkları için ödüllendirildiğinde, bireysel motivasyon oluşmasına, olumlu şirket kültürünün teşvik edilmesine, ürün ve hizmetlerin iyileştirilmesine ve daha pek çok şeye yardımcı olur.

                                        <p> İş süreçlerinizi oyunlaştırma pratikleri doğrultusunda gerçekleştirmek, şirket kültürünüzü geliştirmenize ve çalışanlarınızın şirkete bağlılığını kuvvetlendirmenize yardımcı olacaktır.</p>
                                        <ul>
                                            <li>Özelleştirilebilir bilgi yarışmaları ile sürekli gelişim</li>
                                            <li>Kaynak tüketimi takibi ve sürdürülebilirliğe katkı</li>
                                            <li>Çalışanlar arası dengeli rekabet</li>
                                            <li>E-posta gerektirmeden şirket içi çok yönlü ve anlık iletişim</li>
                                            <li>Verimli İK bütçe yönetimi</li>
                                            <li>360 değerlendirme  ile çalışan performans takibi ve raporlanması</li>
                                            <li>Kurumun vizyon, misyon ve değerlerine toplu entegrasyon</li>
                                            <li>Kurumsal bağ ve hafıza gelişimi / Oyunlaştırma ile iş süreçleri iyileştirilmesi</li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default News1