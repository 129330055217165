import React from 'react'
import Select from 'react-select'
function SingleChoiceDropdown({ value, options, setValueFunc, placeholder }) {
    return (
        <Select
            value={value}
            options={options}
            closeMenuOnSelect={true}
            onChange={(e) => setValueFunc(e)}
            filterOption={(option, query) =>
                String(option.data.label).toLocaleLowerCase('tr').includes(query.toLocaleLowerCase('tr'))
            }
            noOptionsMessage={'Seçenek Bulunamadı'}
            placeholder={placeholder}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: 'none',
                    borderRight: 'none',
                    minHeight: 58,
                    fontSize: 13,
                    textAlign: 'start',
                }),
                placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#adadad',
                })
            }}
        />
    )
}

export default SingleChoiceDropdown