import React from 'react'
import HeroSection from '../components/MainContents/HeroSection';
import PromoSection from '../components/MainContents/PromoSection';
import AboutUs from '../components/MainContents/AboutUs';
import FeatureSection from '../components/MainContents/FeatureSection';
import VideoPromoSection from '../components/MainContents/VideoPromoSection';
import PackageSection from '../components/MainContents/PackageSection';
import ScreenshotsSection from '../components/MainContents/ScreenshotsSection';
import OurBlogSection from '../components/MainContents/OurBlogSection';
import Circles from '../components/MainContents/Circles';
import ContactUsSection from '../components/MainContents/ContactUsSection';
import ClientSection from '../components/MainContents/ClientSection';
import SubscribeSection from '../components/MainContents/SubscribeSection';
import PartnerForm from '../components/MainContents/PartnerForm';
import EventsSection from '../components/MainContents/EventsSection';

function HomePage() {
    return (
        <div class="main" xmlns="http://www.w3.org/1999/html">
            <HeroSection />
            <PromoSection />
            <EventsSection />
            <div class="overflow-hidden">
                <AboutUs />
            </div>
            <FeatureSection />
            <VideoPromoSection />
            <PackageSection />
            {/* <ScreenshotsSection /> */}
            <OurBlogSection />
            <Circles />
            <ContactUsSection />
            <p id="trusted" />
            <ClientSection />
            <PartnerForm />
            {/* <p id="subscribe" /> */}
            {/* <SubscribeSection /> */}
        </div>
    )
}

export default HomePage