import React from 'react'

function OurBlogSection() {
    return (
        <section class="our-blog-section ptb-100 primary-bg">
            <div class="container" id="news">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-heading mb-5 text-white pd-t">
                            <h2 class="text-white">Son Haberler</h2>
                            <p class="lead">
                                Ming ile ilgili en güncel haberler için takipte kalın
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="single-blog-card card border-0 shadow-sm white-bg">
                            <span class="category position-absolute badge badge-pill badge-danger"
                                style={{ backgroundColor: '#0d6ace' }}>Yeni</span>
                            <a href="/news-3" title="blog haberler" hreflang="tr"> <img
                                src="img/blog/1.webp" width="350" height="221"
                                class="news-style card-img-top position-relative" alt="blog" /></a>
                            <div class="card-body " style={{ height: '350px' }}>
                                <h3 class="h5 card-title"><a href="/news-3"
                                    title="Yorglass’tan çalışanlar arasında uçtan uca iletişimi artıran yenilikçi uygulamalar"
                                    hreflang="tr">
                                    İK stratejilerine ilham veren Ming uygulaması fuara çıkarma yapacak!</a></h3>
                                <h6 class="post-meta mb-2 list-inline meta-list font-weight-bold" style={{ color: "#544a4a" }}>
                                    Ocak 19, 2023 </h6>
                                <p class="card-text">Ming Mobilefest İletişim Teknolojileri Fuarı ve Konferansı’nda
                                    olacak.<br />
                                </p><a href="/news-3" class="detail-link" hreflang="tr">Daha fazla <span
                                    class="ti-arrow-right"></span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="single-blog-card card border-0 shadow-sm white-bg">
                            <span class="category position-absolute badge badge-pill badge-primary"
                                style={{ backgroundColor: "#d11f31" }}>Teknoloji</span>
                            <a href="/news-1" title="Yeni haberler" hreflang="tr"> <img
                                src="img/blog/3.webp" width="350" height="221"
                                class=" news-style card-img-top position-relative" alt="blog haberler" /></a>
                            <div class="card-body " style={{ height: "350px" }}>
                                <h3 class="h5 card-title"><a href="/news-1" title="Ming Eylülde Yayında !"
                                    hreflang="tr">Ming Eylülde Yayında !</a></h3>
                                <h6 class="post-meta mb-2 list-inline meta-list font-weight-bold" style={{ color: "#544a4a" }}>
                                    Eylül 01, 2022 </h6>
                                <p class="card-text">Heyecanla beklenen, İç İletişim Ve Çalışan Performans Platformu Eylülde
                                    sizlerle buluşuyor.</p><br />
                                <a href="/news-1" class="detail-link" title="yakında yeni haber"
                                    hreflang="tr">Daha fazla <span class="ti-arrow-right"></span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="single-blog-card card border-0 shadow-sm white-bg">
                            <span class="category position-absolute badge badge-pill badge-danger"
                                style={{ backgroundColor: "#d11f31" }}>Teknoloji</span>
                            <a href="/news-2" title="blog haberler" hreflang="tr"> <img
                                src="img/blog/2.webp" width="350" height="221"
                                class="news-style card-img-top position-relative" alt="blog" /></a>
                            <div class="card-body " style={{ height: "350px" }}>
                                <h3 class="h5 card-title"><a href="/news-2"
                                    title="Yorglass’tan çalışanlar arasında uçtan uca iletişimi artıran yenilikçi uygulamalar"
                                    hreflang="tr">Yorglass’tan çalışanlar arasında uçtan uca iletişimi artıran yenilikçi
                                    uygulamalar</a></h3>
                                <h6 class="post-meta mb-2 list-inline meta-list font-weight-bold" style={{ color: "#544a4a" }}>
                                    Eylül 12, 2022 </h6>
                                <p class="card-text">Cama hayat veren Yorglass, sosyal platform Ming ile bir yenilik
                                    ekledi.<br />
                                </p><a href="/news-2" class="detail-link" hreflang="tr">Daha fazla <span
                                    class="ti-arrow-right"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurBlogSection