import React from 'react'

function PromoSection() {
    return (
        <section class="promo-section ptb-100">
            <div class="circles">
                <div class="point animated-point-1"></div>
                <div class="point animated-point-2"></div>
                <div class="point animated-point-3"></div>
                <div class="point animated-point-4"></div>
                <div class="point animated-point-5"></div>
                <div class="point animated-point-6"></div>
                <div class="point animated-point-7"></div>
                <div class="point animated-point-8"></div>
                <div class="point animated-point-9"></div>
                <div class="point animated-point-10"></div>
                <div class="point animated-point-11"></div>
                <div class="point animated-point-12"></div>
                <div class="point animated-point-13"></div>
                <div class="point animated-point-14"></div>
                <div class="point animated-point-15"></div>
                <div class="point animated-point-16"></div>
                <div class="point animated-point-17"></div>
                <div class="point animated-point-18"></div>
                <div class="point animated-point-19"></div>
                <div class="point animated-point-20"></div>
            </div>
            <div class="container" id="features">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-8">
                        <div class="section-heading text-center mb-5 pd-t font-card" >
                            <h2>Verimli İK Bütçesi Yönetimi</h2>
                            <p class="lead">
                                Kendi puan ekonomisi ile yönetilen market uygulaması ile motivasyon bütçenizi market üzerinde kalibre edin
                                ve çalışanlarınızın kendilerini motive edecek ürünler almasına olanak tanıyın.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row equal">
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 resize-card">
                            <div class="circle-icon mb-5">
                                <span class="ti-import text-white"></span>
                            </div>
                            <h5>Hızlı Kurulum</h5>
                            <p>Esnek ve yüksek performanslı cloud sistemi.
                                <br />Kuruma özel markalama ve özelleştirme imkânı.
                                <br />Tüm çalışanlar için tek uygulama.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 resize-card">
                            <div class="circle-icon mb-5">
                                <span class="ti-location-arrow text-white"></span>
                            </div>
                            <h5>Kolay Kullanım </h5>
                            <p>Yüksek kullanıcı deneyimine sahip arayüz.
                                <br />Alışılmış deneyimlere paralel bileşen yapısı.
                                <br />Eğitim gerektirmeyen kolay anlaşılabilir uygulama yapısı.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 resize-card">
                            <div class="circle-icon mb-5">
                                <span class="ti-tag text-white"></span>
                            </div>
                            <h5>Esnek Entegrasyon </h5>
                            Success Factors<br />
                            Google Workspace<br />
                            Microsoft 365<br />
                            Kolay İK<br />
                            Ensemble<br />
                            SAP
                        </div>
                    </div>
                </div>
                <div class="row equal m-t-30 " >
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 resize-card">
                            <div class="circle-icon mb-5">
                                <span class="ti-lock text-white"></span>
                            </div>
                            <h5>Yüksek Güvenlik ve Uyumluluk</h5>
                            <p>GDPR ve KVKK uyumluluğu.
                                <br />Sunucu ve uygulama güvenliği; ISO, CSA/CCM, IRS 1075 vb. sertifikalarına sahip yüksek güvenlik seviyesi
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 resize-card">
                            <div class="circle-icon mb-5">
                                <span class="ti-plus text-white"></span>
                            </div>
                            <h5>Kendi Kendini Yöneten Puan Ekonomisi </h5>
                            <p>İK bütçene göre otomatik hesaplanan puan değeri.
                                <br />Zengin ürün yelpazesi ve market yönetimi.
                                <br />Şirkete özel sadakat sistemi.

                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 resize-card">
                            <div class="circle-icon mb-5">
                                <span class="ti-stats-up text-white"></span>
                            </div>
                            <h5>Dijital Sürdürülebilirlik</h5>
                            <p><br />Yazılı ve basılı iş süreçlerinin dijitalleştirilmesiyle sürdürülebilir bir çalışma modeli.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PromoSection