import React from 'react'

function Kvkk2() {
    return (
        <div class="main">

            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 id="kvkk-2" style={{ color: "white" }} class="post-title">Kişisel Verileri Koruma Mevzuatı Uyarınca Genel Aydınlatma Metni</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-wrapper">
                                    <div class="post-content">
                                        <p>Ming olarak, kişisel verilerinizin güvenliğine ve gizliliğine önem veriyor ve bu kapsamda kişisel verilerinize ilişkin her aşamada, hukuka aykırı olarak erişilmesini ve işlenmesini önlemek
                                            ve muhafazasını sağlamak için en üst seviyede güvenlik tedbirlerini almaya çalışıyoruz.</p>

                                        <p>6698 sayılı Kişisel Verilerin Korunması Kanunu'na <em>(“<strong>Kanun</strong>”)</em> göre <strong>Ming Danışmanlık Yazılım ve Pazarlama Limited Şirketi</strong> (<em>Bundan sonra “<strong>Ming</strong>” olarak anılacaktır</em>) sizinle ilgili kişisel verileri işlemesi sebebiyle veri sorumlusu ve/veya veri işleyen olarak tanımlanmaktadır. Ming<strong>,</strong> Kişisel Verilerin Korunması Mevzuatı Uyarınca Genel Aydınlatma Metni <em>(“<strong>Aydınlatma Metni</strong>”)</em> KVKK’nın 10. maddesinde yer alan “Veri Sorumlusu’nun Aydınlatma Yükümlülüğü” başlıklı maddesi uyarınca; veri sorumlusunun kimliği, kişisel verilerinizin toplanma yöntemi ve hukuki sebebi, bu verilerin hangi amaçla işleneceği, kimlere ve hangi amaçla aktarılabileceği, veri işleme süresi ve KVKK’nın 11. maddesinde sayılan haklarınızın neler olduğu ile ilgili bilgilendirme amacıyla hazırlanmıştır.</p>

                                        <p>Kişisel verilerinizi, Kanun ve ilgili yasal mevzuat kapsamında ve “veri sorumlusu ve/ veya veri işleyen” sıfatımızla aşağıda detaylı şekilde belirtmiş olduğumuz kapsamda işlemekteyiz. Bu sebeple işbu metin, ilgili kişileri bilgilendirmek için hazırlanmıştır. İşbu bilgilendirme formu, Şirketimiz ile imzalamış olduğunuz her türlü sözleşme ve hizmet alımına yönelik taleplerinizin eki ve ayrılmaz bir parçasıdır.</p>

                                        <p><strong>1. TANIMLAR</strong></p>

                                        <p><strong>Açık Rıza:</strong> Kişisel verilerinizin işlenmesi, saklanması veya aktarılması hususlarda bilgilendirildiğiniz ve özgür iradeniz ile verilen rıza. </p>

                                        <p><strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.  </p>

                                        <p><strong>Kişisel Verilerin İşlenmesi:</strong> Kişisel verilerin otomatik, yarı otomatik veya otomatik olmayan yollarla oluşturulması, elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması gibi veriler üzerinde gerçekleştirilen her türlü işlem. </p>

                                        <p><strong>Veri Sorumlusu<em>:</em></strong>* Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi, ifade eder. Örnek vermek gerekirse Ming; çalışanları, web sitesi ziyaretçileri ve tedarikçi firmalar ile yapılan sözleşmeler kapsamında veri sorumlusudur.</p>

                                        <p><strong>Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişidir. Örneğin; Ming Hizmet Sözleşmesi kapsamında anlaştığı şirketlerin yetkisine dayanarak işlediği veriler bakımından veri işleyen konumundadır.</p>

                                        <p><strong>2. KİŞİSEL VERİLERİN İŞLENMESİ ŞARTLARI</strong></p>

                                        <p>Kişisel verileriniz yasal zorunluluklar ve başta müşterilerimize hizmet sunabilmek amaçlarıyla işlenmektedir. Kanun veri işlenmesini beli şartlara bağlamış olup bunlar aşağıdaki şekildedir;</p>

                                        <ol>

                                            <li>Kanunlarda açıkça öngörülmesi.</li>

                                            <li>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması.</li>

                                            <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</li>

                                            <li>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması.</li>

                                            <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması.</li>

                                            <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması.</li>

                                            <li>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>

                                        </ol>

                                        <p>Ming olarak Kanun’un ilgili maddesinde belirtilen haller dışında ancak veri sahiplerinin açık rızası alınmak sureti ile veri işlemekteyiz.</p>

                                        <p><strong>Kişisel Verilerin İşlenmesi İlişkin İlkeler;</strong></p>

                                        <ul>

                                            <li>Hukuka ve dürüstlük kurallarına uygun olma,</li>

                                            <li>Doğru ve gerektiğinde güncel olma,</li>

                                            <li>Belirli, açık ve meşru amaçlar için işlenme,</li>

                                            <li>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma,</li>

                                            <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme.</li>

                                        </ul>

                                        <p><strong>a. Veri Sorumlusu Olarak;</strong> Kanun uyarınca kişisel verileriniz, aşağıda ve ayrıca hazırlanan veri sahibi gruplara özgü aydınlatma metinlerinde açıklanan amaçlar kapsamında, hukuka ve dürüstlük kurallarına uygun bir şekilde işlenebilecek, kaydedilebilecek, saklanabilecek, sınıflandırılabilecek, güncellenebilecek ve mevzuatın izin verdiği hallerde ve/veya işlendikleri amaçla sınırlı olarak 3. kişilere açıklanabilecek/aktarılabilecektir.</p>

                                        <p>Şirketimiz kişisel verilerinizi veri güvenliğini sağlayarak işlemeye başlayacaktır. Kişisel verilerinizi işlerken bir veya birden fazla veri işleyeni yetkilendirerek gerekli güvenlik düzeyini sağlamak suretiyle verilerinizi onlara da işletebiliriz. </p>

                                        <p><strong>b. Veri İşleyen Olarak; kişisel verileriniz, Kanun ve Veri Sorumlusu tarafından tanınan yetki uyarınca ve Veri Sorumlu’nun belirlediği amaçlar kapsamında</strong> hukuka ve dürüstlük kurallarına uygun bir şekilde işlenebilecek, kaydedilebilecek, saklanabilecek, sınıflandırılabilecek, güncellenebilecek ve mevzuatın izin verdiği hallerde ve/veya işlendikleri amaçla sınırlı olarak 3. kişilere açıklanabilecek/aktarılabilecektir.</p>

                                        <ul>

                                            <li>Kişisel verilerin hukuka aykırı olarak işlenmesini önlemek,</li>

                                            <li>Kişisel verilere hukuka aykırı olarak erişilmesini önlemek,</li>

                                            <li>Kişisel verilerin muhafazasını sağlamak amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri almak hususunda veri sorumlusu ile müştereken sorumluyuz. </li>

                                        </ul>

                                        <p><strong>Ming Veri İşleyen olarak hareket ettiğinde Kanun’un amacı ve ruhuna uygun olarak gerekli tüm yükümlülükleri yerine getirmekte ise de işlenen verilerin yetkiye dayalı işlendiği göz önüne alındığında Veri Sorumlusunun aydınlatma yükümlülüğü ve gerekli hallerde açık rıza alma yükümlülüğü kendisi üzerindedir.</strong> Ming Veri İşleyen olarak kişisel verilerinizin hukuka aykırı işlenmesini, erişilmesini önlemek ve en üst düzey güvenlik önlemlerini almak konusunda oldukça özenle hareket etmektedir.</p>

                                        <p><strong>3. MİNG OLARAK İŞLEDİĞİMİZ KİŞİSEL VERİLER VE HUKUKİ DAYANAĞI</strong></p>

                                        <p><strong>a. Veri Sorumlusu Olarak</strong>; çalışanlarımız ile işe alım süreçlerinin yürütülmesi, hizmet verilen veya alınan şirketler ile ticari ilişkiler kapsamında fatura düzenlenmesi ve cari hesap tutulması vb. süreçlerin yürütülebilmesi amacıyla minimum düzeyde kişisel veri alınmaktadır. </p>

                                        <p>Kimlik (Ad-Soyad, T.C. No), İletişim (Adres, Telefon, E-mail), Finans (Banka Hesap Numrası), Özlük (Çalışan SGK Bilgileri), Hukuki İşlem (İmza, Tarih) kişisel verileri işlenmektedir. </p>

                                        <p>Kişisel verileriniz ilgili mevzuat hükümleri gereğince Ming tarafından gerçekleştirilecek hizmetleri sunabilmek ve bu hizmetlerin kalitesini artırabilmek için kişisel veri sahibi gruplara özgü hazırlanan aydınlatma metinlerinde detayları açıklanmak üzere aşağıdaki sebepler ile işlenmektedir;</p>

                                        <ul>

                                            <li>Çalışanlar İçin İş Akdi Ve Mevzuattan Kaynaklı Yükümlülüklerin Yerine Getirilmesi</li>

                                            <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>

                                            <li>Finans Ve Muhasebe İşlerinin Yürütülmesi</li>

                                            <li>Hukuk İşlerinin Takibi Ve Yürütülmesi</li>

                                            <li>İnsan Kaynakları Süreçlerinin Planlanması</li>

                                            <li>Mal / Hizmet Satış Süreçlerinin Yürütülmesi</li>

                                            <li>Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi</li>

                                            <li>Sözleşme Süreçlerinin Yürütülmesi</li>

                                            <li>Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</li>

                                            <li>Yönetim Faaliyetlerinin Yürütülmesi Yürütülmesi amaçları olmak üzere 5/1 Açık Rıza, 5/2a Kanunlarda Açıkça Öngörülmesi, 5/2c Sözleşmenin Kurulması veya İfası, 5/2e Bir hakkın tesisi, kullanılması, korunması, 5/2f Meşru Menfaat ve 6/3 Kanunlarda Öngörülmesi hukuki sebepleri ile işlenmektedir.</li>

                                        </ul>

                                        <p><strong>b. Veri İşleyen Olarak;</strong> Kişisel verileriniz Veri Sorumlusu tarafından Ming’e aktarılmakta olup işlenen veriler ve hukuki dayanakları Veri Sorumlusu tarafından belirlenmektedir. Ming olarak Veri Sorumlusu tarafından Şirketimize verilen yetkiye dayanarak ilgili veriler Veri İşleyene ait Kanun ve mevzuattaki yükümlülüklerimize uygun şekilde işlenmektedir.</p>

                                        <p>Ming tarafından kişisel veriler Veri İşleyen olarak; Veri Sorumlusu şirketin kullanıcı hesabı oluşturulmasını istediği Çalışanlarının kişisel verilerini elektronik veya fiziki ortamda Ming’e aktarması, Veri Sorumlusunun Çalışanları tarafından kullanıcı hesabı oluşturulması veya Veri Sorumlusu şirket içinde belirlenecek Admin veya Adminler tarafından kişisel verilerin sisteme girilmesi ile otomatik ya da otomatik olmayan yöntemlerle elde edilmektedir.  </p>

                                        <p>Ming olarak hazırlamış olduğumuz iç iletişim platformu ile sözlü, yazılı ya da elektronik olarak kimlik (Ad- Soyad, Doğum Tarihi, Cinsiyet), iletişim (E-posta, Cep telefonu), özlük (Sicil No, İşe Giriş-Çıkış Tarihi, İşyeri Unvan ve Adresi, Departman Bilgisi), çerez kayıtları ve işlem güvenliğine dair bir kısım veriler (IP adresi bilgileri, log kayıtları, şifre ve parola bilgileri, internet sitesi/ uygulama giriş çıkış bilgileri, mobil cihazınız işletim sistemi ve işletim sistemi versiyon numarası) Ming tarafından işlenebilmektedir. Hangi kişisel verilerin işleneceği ve hangi amaç ile işleneceği münhasıran Ming’e Veri İşleyen sıfatı sebebiyle veri aktaran Veri Sorumlusunun inisiyatifindedir.</p>

                                        <p>Veri Sorumlusu tarafından yapılacak Aydınlatma Yükümlülüğü ve açık rıza yükümlülüğü saklı kalmak üzere Ming tarafından Veri İşleyen olarak aşağıdaki sebepler ile verileriniz işlenebilmektedir;</p>

                                        <ul>

                                            <li>Performans Değerlendirme Süreçlerinin Yürütülmesi, </li>

                                            <li>Çalışan Memnuniyeti Ve Bağlılığı Süreçlerinin Yürütülmesi, </li>

                                            <li>Çalışanlar İçin Yan Haklar Ve Menfaatleri Süreçlerinin Yürütülmesi, </li>

                                            <li>Görevlendirme Süreçlerinin Yürütülmesi, </li>

                                            <li>İletişim Faaliyetlerinin Yürütülmesi, </li>

                                            <li>İnsan Kaynakları Süreçlerinin Planlanması, </li>

                                            <li>İş Faaliyetlerinin Yürütülmesi / Denetimi, </li>

                                            <li>İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi, </li>

                                            <li>Organizasyon Ve Etkinlik Yönetimi, </li>

                                            <li>Stratejik Planlama Faaliyetlerinin Yürütülmesi, </li>

                                            <li>Yetenek / Kariyer Gelişimi Faaliyetlerinin Yürütülmesi amaçları olmak üzere 5/1 Açık Rıza, 5/2c Sözleşmenin Kurulması veya İfası, 5/2e Bir hakkın tesisi, kullanılması, korunması, 5/2f Meşru Menfaat ve 6/3 Kanunlarda Öngörülmesi hukuki sebepleri ile işlenmektedir.</li>

                                        </ul>

                                        <p>Bu amaçlarla işlediğimiz kişisel verilerinizi yurt içinde ve yurt dışında bulunan iştiraklerimiz, iş ortaklarımız, tedarikçilerimiz, şirket yetkililerimiz, hissedarlarımız, grup şirketlerimiz ve Kanunen yetkili kamu kurumları ve özel kişilerle paylaşabiliriz. </p>

                                        <p><strong>5. KİŞİSEL VERİLERİNİZİN PAYLAŞILMASI</strong></p>

                                        <p>Gerektiğinde kişisel verileriniz; Ming ve/veya iştirakleri ile bunların alt işverenlerine, kanunen yetkili gerçek ve/veya tüzel üçüncü kişi kurum ve kuruluşlara, <strong>Ming’in</strong> iş ortaklarına, alt işverenlerine, tedarikçilerine, ürün ve hizmetlerini yürütmek üzere sözleşmesel olarak hizmet aldığı ya da herhangi bir alanda işbirliği yaptığı, yurt içinde veya yurt dışında yerleşik bulunan gerçek ve/veya tüzel üçüncü kişi kurum ve kuruluşlara, KVK Kanunu’nun 8. ve 9. maddesinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.</p>

                                        <p>Önemle belirtmek isteriz ki, Ming olarak ilk önceliğimiz kişisel verilerinizin güvenliği olup bunu gerçekleştirmek adına en üst düzey güvenlik önlemlerini sağlayan serverlar kullanılmaktadır. Başta güvenlik tedbirlerinin alınması olmak üzere verileriniz yurt dışındaki serverlara aktarılabilmektedir. </p>

                                        <p>Sistemimiz uçtan uca 256 bit şifreleme sistemi (Secure Sockets Layer) ile korunmaktadır. Bu sayede araya girmeye çalışan kötü niyetli yazılım ve kişiler engellenmektedir. Bunun yanında olası zararları engellemek için bulut sunucular arkasında çalışmaktadır. Bu sayede aşırı yükler oluştuğunda istemciyi farklı bir buluta yönlendirir ve veri kaybını engeller.</p>

                                        <p>Verilerin barındırıldığı sunuculara, kopyalanamaz ve yeniden üretilemez bir şifreleme sistemi olan RSA ile bağlanılmaktadır. Sunucularımız Firewall arkasında, saldırılara karşı koruma altındadır. Sunucu kimliklerimiz değişkendir ve her zaman farklı lokasyon görüntüsü ile saldırılara karşı ekstra önlemler almaktadır.</p>

                                        <p>Sunucu %99,99 uptime garantisi vermekte ve online olmanız için gerekli tüm çalışmalar yapılmış ve sunucularımızdan biri dahi çevrimdışı olursa mevcut yükler diğer sunucularımıza dağılmaktadır. Verilerimiz şifrelenerek ve 3. kişilerin görmelerini engelleyecek şekilde saklanmakta ve yedekleri günlük olarak tutulmaktadır.</p>

                                        <p>Her türlü acil durum karşısında hızlı ve sorunsuz olarak sistemlerin çalışması sağlayacak şekilde tatbikatlar gerçekleştirilerek hem hızlı hem de anlık olarak verileriniz daima sizlere sunulmaya hazır olmaktadır.</p>

                                        <p>Verileriniz Bulut sistemlerde saklanır ve AES-256 şifreleme algoritması ile şifrelenir. AES-256 Şifreleme tekniği dünya üzerinde kırılması en zor şifreleme tekniklerinin başında gelir ve asgari düzeyde bir şifreleme desteği sağlar.</p>

                                        <p><strong>İlgili aktarım hem veri sahiplerinin hem de Ming’in meşru menfaatlerini korumak amacıyla yapılmakta olup bu konuya ilişkin ayrıca açık rızanız istenmektedir. İlgili açık rızanın alınmasını Veri Sorumlusu sıfatı ile hareket ederken Ming’te olmakla beraber, Ming’in Veri İşleyen sıfatı ile hareket ettiği durumlarda kendisine veriyi aktaran Veri Sorumlusu üzerindedir.</strong> </p>

                                        <p>Kişisel verileriniz; Şirketimiz, iştiraklerimiz, iş ortaklarımız ve çalışanlarımız aracılığıyla internet sitemiz ve sair her türlü kanallar yardımıyla, ilgili mevzuat hükümlerine uygun olarak sözlü, yazılı veya elektronik ortamda toplanabilmektedir.</p>

                                        <p><strong>6. KİŞİSEL VERİLERİNİZİN İMHA EDİLMESİ</strong></p>

                                        <p>Şirketimiz, işlenen kişisel verilerinizi ilgili mevzuatta öngörülen süreler kadar saklamaktadır. Ancak kişisel verilerinizin saklanma süresine ilişkin mevzuatta ayrıca bir sürenin öngörülmemiş olması hâlinde; kişisel verileriniz Şirketimizin sunduğu hizmetlere ilişkin Şirketimizin uygulamaları ve ticari teamüller uyarınca işlenmesi gereken süre kadar ve bu süreden sonra sadece olası hukuki uyuşmazlıklarda delil teşkil etmesi amacıyla belirlenen makul süreler boyunca saklanmaktadır. Belirtilen sürelerin sona ermesinden sonra KVK Kanunu’nun 7. maddesi uyarınca söz konusu kişisel verileriniz ilk imha tarihinde silinmekte, yok edilmekte veya anonim hâle getirilmektedir.</p>

                                        <p><strong>7. KİŞİSEL VERİLERİNİZLE İLGİLİ HAKLARINIZ</strong></p>

                                        <p>İlgili kişiler, kişisel verilerine ilişkin haklarını öncelikle veri sorumlusuna iletmek zorundadırlar. Kanunun 14'üncü maddesine göre Kişisel Verileri Koruma Kurulu'na doğrudan şikâyette bulunulamaz. KVK Kanunu’nun 11. maddesi çerçevesinde Şirketimize başvurarak aşağıda yer alan taleplerinizi bize iletebilirsiniz:</p>

                                        <ol>

                                            <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme, işlenmişse buna ilişkin bilgi talep etme</li>

                                            <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme</li>

                                            <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri öğrenme</li>

                                            <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bu eksiklik veya yanlışlıkların düzeltilmesini isteme</li>

                                            <li>KVK Kanunu’nun 7. maddesi kapsamında kişisel verilerinizin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini, yok edilmesini ya da anonim hâle getirilmesini talep etme</li>

                                            <li>(d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme</li>

                                            <li>İşlenen kişisel verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun doğmasına itiraz etme</li>

                                            <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep etme.</li>

                                        </ol>

                                        <p><strong>Başvuru Yöntemi</strong></p>

                                        <p>Başvurunuzu:</p>

                                        <ul>

                                            <li><em>Harbiye Mahallesi Asker Ocağı Caddesi Süzer Plaza Bl. No: 6/9 Şişli, İstanbul</em> adresine yazılı olarak;</li>

                                            <li>Güvenli elektronik imza veya mobil imza ile;</li>

                                            <li>Elektronik posta adresinizin sistemimizde kayıtlı olması halinde aynı e-posta adresini kullanarak info@mingapp.co e-posta göndererek yapabilirsiniz.</li>

                                        </ul>

                                        <p>Başvurunuzda;</p>

                                        <ul>

                                            <li>Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,</li>

                                            <li>Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numaranızın, yabancı iseniz uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,</li>

                                            <li>Tebligata esas yerleşim yeri veya iş yeri adresinizin,</li>

                                            <li>Varsa bildirime esas elektronik posta adresi, telefon ve faks numaranızın,</li>

                                            <li>Talep konunuzun,</li>

                                        </ul>

                                        <p>bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de başvuruya eklenmesi gerekmektedir. Ming, kimliğinizi doğrulama hakkını saklı tutar.</p>

                                        <p>İlgili kişinin kendisi dışında bir kişinin talepte bulunması için konuya ilişkin olarak ilgili kişi tarafından başvuruda bulunacak kişi adına düzenlenmiş noter tasdikli özel vekâletname bulunmalıdır. </p>

                                        <p>Bu kapsamda usulüne uygun bir şekilde Şirketimize ileteceğiniz talepleriniz, <strong>en geç otuz gün içerisinde</strong> sonuçlandırılacaktır. Söz konusu taleplerinizin sonuçlandırılmasının ayrıca bir maliyeti gerektirmesi halinde, Şirketimiz tarafından başvuru sahibinden Kişisel Verileri Koruma Kurulu <em>(“Kurul”)</em> tarafından belirlenen tarifedeki ücret alınacaktır. Şirketimizce CD, flash bellek gibi bir kayıt ortamı üzerinden başvurunuza cevap verilmesi halinde ise kayıt ortamının maliyetini geçmeyecek şekilde belirlenen ücret talep edilebilecektir.</p>

                                        <p>Şirketimiz, talep konusu kişisel verinin gerçek sahibi olup olmadığınızı tespit etmek adına tarafınızdan gerekli bilgi ve belgeleri talep edebilir ve başvurunuzda belirtilen hususları netleştirmek adına tarafınıza başvurunuz ile ilgili soru yöneltebilir. </p>

                                        <p>Şirketimiz talebinize ilişkin cevabını yazılı olarak veya elektronik ortamda tarafınıza bildirecektir. KVK Kanunu’nun 14. maddesi gereğince başvurunuzun reddedilmesi, Şirketimizce verilen cevabı yetersiz bulmanız veya başvurunuza süresi içerisinde cevap verilmemesi hâllerinde; Şirketimizin cevabını öğrendiğiniz tarihten ya da cevap vermememiz hâlin de cevap süremizin bitimi tarihinden itibaren 30 (otuz) ve her hâlde başvuru tarihinden itibaren 60 (altmış) gün içinde Kurul’a şikâyette bulunabilirsiniz.</p>

                                        <p>Başvuru sırasında uyulması gereken usul kuralları ve daha detaylı bilgiye Kişisel Verileri Koruma Kurumu'nun &quot;<a href="http://www.resmigazete.gov.tr/eskiler/2018/03/20180310-6.htm" title="kişisel veri korunma kanunu kvkk">Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ</a>&quot;inden ulaşabilirsiniz.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Kvkk2