import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MingPage from "./pages/MingPage";
import News3 from "./pages/News/News3";
import News2 from "./pages/News/News2";
import News1 from "./pages/News/News1";
import Kvkk1 from "./pages/FooterPages/Kvkk1";
import Kvkk2 from "./pages/FooterPages/Kvkk2";
import Platform from "./pages/FooterPages/Platform";
import Cookie from "./pages/FooterPages/Cookie";
import PrivacyPolicy from "./pages/FooterPages/PrivacyPolicy";
import Download from "./pages/FooterPages/download";
import { useLayoutEffect } from "react";
import EventDetail from "./pages/EventDetail";
import Events from "./pages/Events";

function App() {

  useLayoutEffect(() => {
    if (window.location.pathname === '/imza' || window.location.pathname === '/imza/') {
      window.location.replace('https://imza.mingapp.co/')
    }
  }, [])
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="ming" element={<MingPage />} />
          <Route path="events" element={<Events />} />
          <Route path="news-3" element={<News3 />} />
          <Route path="news-1" element={<News1 />} />
          <Route path="news-2" element={<News2 />} />
          {/* <Route path="kvkk-1" element={<Kvkk1 />} /> */}
          <Route path="kvkk1" element={<Kvkk1 />} />
          <Route path="kvkk2" element={<Kvkk2 />} />
          <Route path="platform" element={<Platform />} />
          <Route path="cookie" element={<Cookie />} />
          <Route path="event-detail" element={<EventDetail />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="download" element={<Download />} />
          <Route path="download/*" element={<Download />} />
          {/* <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
