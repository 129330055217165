import React from 'react'

function MingPage() {
    return (
        <div class="main">

            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('/img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 id="ming" style={{ color: "white" }} class="post-title">Ming Nedir?</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-wrapper">
                                    <div class="post-content">
                                        <p>Ming; çalışanların birbirleri ve kurum ile arasındaki etkileşimi artırmayı, oyunlaştırma dinamiklerini kullanarak uygulama içi aksiyonlardan puan kazandıran, kazanılan puanları market modülü üzerinden ödüle dönüştüren kurum içi iletişim platformudur.</p><br /><br />

                                        Ming olarak sizlere, yetişmiş iş gücünüzün memnuniyetini artırma, yeni katılan çalışma arkadaşlarınızı daha hızlı adapte etme, şirket hedeflerinize çalışanlarınızı dahil etme ve saha ya da ofis çalışanı olması fark etmeksizin tüm çalışanlarınız ile anlık olarak etkileşim kurma ve onları dinleme imkânlarınızı tek bir platformda sağlıyoruz.<br /><br />
                                        Modüllerimize entegre ettiğimiz oyunlaştırma enstrümanları ve pratiklerinden yararlanarak sürdürülebilir bir kurum içi sadakat sistemi sunuyoruz. Performans sistemi ile saha çalışanlarınızın bireysel olarak gelişmelerini gözlemlemeniz ve prim sistemine dahil etmeniz konusunda sizlere yardımcı oluyoruz.
                                        <br /><br /><br />
                                        <h5>Neden “ oyunlaştırma ” ?</h5><br />
                                        <p>
                                            Oyunlaştırma artık dünyanın her yerindeki büyük şirketlerin arzu edilen sonuçlara ulaşmak için kullandığı en verimli ve etkili araçlardan biri. Oyunlaştırma; sosyalleşme, gelişmeye teşvik, geri bildirim, eğlence ve iş birliği gibi mekanizmalara sahiptir. Çoğu insan, doğal olarak, yaptıkları görevlerin sonucunda keyif almak veya ödüllendirilmek ister. Bir ödül sisteminin uygulandığı iş yerlerinde katılım ve motivasyon da doğal olarak artar. Mesela, çalışanlar diğer departmanlarla iş birliği yaptıkları için ödüllendirildiğinde, bireysel motivasyon oluşmasına, olumlu şirket kültürünün teşvik edilmesine, ürün ve hizmetlerin iyileştirilmesine ve daha pek çok şeye yardımcı olur. İş süreçlerinizi oyunlaştırma pratikleri doğrultusunda gerçekleştirmek, şirket kültürünüzü geliştirmenize ve çalışanlarınızın şirkete bağlılığını kuvvetlendirmenize yardımcı olacaktır.
                                        </p>
                                        <br />Genel çalışan üretkenliğini artırır.
                                        <br />Etkileşimi ve bağlılığı artırır.
                                        <br />İş süreçlerinde adaptasyonu ve sürekliliği sağlar.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MingPage