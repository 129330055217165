import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
const EVENTS = [
    {
        id: 0,
        topTitle: "Şirket Kültürüne Lezzetli Bir Dokunuş",
        title: 'Waffle Etkinliği',
        img: '/img/event-detail/waffle.png',
        imgs: [
            "/img/event-detail/waffle1.png"
        ],
        gainTitle: "Waffle Etkinliğinin Şirketimiz İçin Önemi ve Getirileri",
        gains: [
            {
                title: 'Enerji Dolu Atmosfer',
                text: "Waffle'ların enerji verici lezzetiyle dolu bir ortamda, ekibinizin enerji seviyelerini yükseltin ve verimliliği artırın!"
            },
            {
                title: 'Paylaşım ve Birlik',
                text: "Waffle'ları paylaşırken, ekip ruhunu güçlendirin ve birbirinizle daha yakın bağlar kurun. Lezzetli deneyimlerinizi paylaşarak ortak bir hedefe doğru ilerleyin!"
            },
            {
                title: 'Motivasyonu Artırın',
                text: "Lezzetli waffle'larla dolu bir etkinlik, herkesin moralini ve motivasyonunu artırarak daha başarılı sonuçlar elde etmenize yardımcı olur!"
            },
            {
                title: 'İletişimi Geliştirin',
                text: "Waffle yemek, ekibiniz arasındaki iletişimi güçlendirir. Birbirinize güzel deneyimlerinizi paylaşın ve daha sağlam bir iletişim ağı oluşturun!"
            },
            {
                title: 'Etkileşimi Artırın',
                text: "Etkinlik boyunca katılımcılar arasındaki etkileşimi artırın. Yeni bağlantılar kurun, fikir alışverişi yapın ve keyifli sohbetler edinin. Birbirinizi daha yakından tanıyarak işbirliğinizi güçlendirin!"
            },
            {
                title: 'Keyifli Vakit Geçirin',
                text: "Lezzetli waffle'lar, mevsim meyveleri, şekerlemeler ve soslar eşliğinde keyifli vakit geçirin. İş stresinden uzaklaşarak bir araya gelin ve güzel anılar biriktirin!"
            },
        ],
        texts: [
            "Waffle Etkinliği,ekip üyelerinin bir araya gelip hem işlerini halletmelerini hem de lezzetli waffle'ların tadını çıkarmalarını sağlar.Bu etkinlik, iş ortamında birlik ve beraberlik duygusunu güçlendirirken aynı zamanda keyifli bir mola ve dinlenme fırsatı sunar. Waffle Etkinliği, çalışanlarımız arasındaki iletişimi artırır ve iş yerinde pozitif bir atmosfer oluşturur.",
            "İş stresinin yoğun olduğu zamanlarda çalışanlara verilen bu mola, sadece fiziksel olarak dinlenmelerine değil, aynı zamanda zihinsel olarak da yenilenmelerine olanak tanır.",
            "Sonuç olarak, bu tür organizasyonlar, şirket kültürünü zenginleştirerek çalışan memnuniyetini ve verimliliği artırır.",
        ],
    },
    {
        id: 1,
        title: 'Kahve Etkinliği',
        topTitle: 'Şirket Kültürünü Canlandıran Lezzetli Bir Deneyim!',

        img: '/img/event-detail/kahve.png',
        imgs: [
            "/img/event-detail/kahve1.png",
            "/img/event-detail/kahve2.png",
        ],
        gainTitle: "Etkinliğin Şirketinize Sağlayacağı Faydalar",

        gains: [
            {
                title: 'Takım Ruhu ve İşbirliği',
                text: "Kahve etkinliği, ekip üyelerinin bir araya gelerek daha yakın bir ilişki kurmalarını sağlar. Bu ortam, takım ruhunu güçlendirir ve işbirliği ile proaktif çalışma kültürünü teşvik eder."
            },
            {
                title: 'Stres Azaltma ve Rahatlama',
                text: "Kahve molası, iş stresini azaltır ve katılımcıların gevşemesine olanak tanır. Bu, çalışanların daha huzurlu ve dengeli bir ruh haline sahip olmalarını sağlar, dolayısıyla iş performansını olumlu yönde etkiler."
            },
            {
                title: 'İletişim ve Bağlantı',
                text: "Kahve etkinliği, katılımcıların rahat bir ortamda bir araya gelerek iletişim kurmalarını teşvik eder. Bu, işyerindeki iletişimi güçlendirir, ekip üyelerinin birbirlerini daha iyi anlamalarını sağlar ve işbirliğini artırır."
            },
            {
                title: 'Uyanıklık ve Odaklanma',
                text: "Uyanıklık ve Odaklanma: Kahve, kafein içeriği sayesinde katılımcıların uyanıklık seviyelerini artırır ve dikkatlerini toplamalarına yardımcı olur. Bu, toplantılar veya stratejik oturumlar gibi iş etkinliklerinde daha verimli bir şekilde çalışmalarını sağlar."
            },

        ],
        texts: [
            "İş yerinde kahve molası zamanı! Kahve tutkunları için özel olarak düzenlenen bu etkinlik, herkesin favori kahve çeşitlerini paylaşabileceği ve yeni lezzetler keşfedebileceği bir ortam sunuyor.",
            "Sonuç olarak, Kahve Etkinliği işyerinde bir şölen havası estirir! Takım ruhu yükselir, stresler sıkı bir kahve keyfiyle uçup gider, iletişim bir tık daha renklenir ve katılımcılar kafeinle dolup taşarak daha neşeli ve odaklanmış bir şekilde çalışır!"
        ],
    },
    {
        id: 2,
        title: 'Dondurma Partisi',
        topTitle: 'Şirket Ruhunu Serinleten Bir Deneyim!',

        img: '/img/event-detail/dondurma.png',
        imgs: [
            "/img/event-detail/dondurma1.png",
            "/img/event-detail/dondurma2.png",
        ],
        gainTitle: "Dondurma Partisi Etkinliğinin Şirketimiz İçin Önemi ve Getirileri",
        gains: [
            {
                title: 'Stres Azaltıcı Mola',
                text: "Lezzetli dondurma, çalışanların stres seviyelerini azaltır ve kısa bir mola vererek rahatlamalarını sağlar. Bu da verimliliği artırır ve iş yerinde daha pozitif bir atmosfer oluşturur."
            },
            {
                title: 'Ekip Ruhunu Güçlendirme',
                text: "Dondurma etkinliği, ekip üyelerinin bir araya gelerek birlikte vakit geçirmesini sağlar. Bu, takım ruhunu güçlendirir, işbirliğini artırır ve şirket içinde daha sağlam bir birlik hissi oluşturur."
            },
            {
                title: 'İletişimi Geliştirme',
                text: "Dondurma yemek, katılımcılar arasında samimi bir ortam oluşturur ve iletişimi geliştirir. Daha rahat bir ortamda bir araya gelmek, iş arkadaşları arasındaki iletişimi güçlendirir ve işbirliğini artırır."
            },
            {
                title: 'Motivasyona Katkı',
                text: "Lezzetli dondurma, çalışanların moralini yükseltir ve motivasyonlarını artırır. Şirketin bu tür etkinliklere değer vermesi, çalışanların işlerine daha bağlı olmalarını sağlar ve şirket kültürünü olumlu yönde etkiler."
            },

        ],
        texts: [
            "İş yerinde yazın tadını çıkarmak için dondurma günü düzenlemeye hazır mısınız? Çeşitli dondurma lezzetleriyle dolu bu etkinlik, çalışanlarınızın sıcak havalarda serinlemesini ve bir araya gelerek keyifli vakit geçirmesini sağlayacak. Renkli şerbetler, meyve parçaları ve çikolata soslarıyla süslenmiş dondurmalar, herkesin favorisi olacak ve birlikte paylaşmanın keyfini çıkarmanızı sağlayacak.",
            "Bu etkinlikler, çalışanların motivasyonunu artırmak ve iş ortamını daha keyifli hale getirmek için harika bir fırsat sunuyor."
        ],
    },
    {
        id: 3,
        title: 'Milkshake Mutluluğu',
        topTitle: 'Şirketinize Tatlı Bir Renk Katın!',
        img: '/img/event-detail/milkshake.png',
        imgs: [
            "/img/event-detail/milkshake1.png",
        ],
        gainTitle: "Milkshake Etkinliğinin Şirketimiz İçin Önemi ve Getirileri",

        gains: [
            {
                title: 'Lezzetli Mola',
                text: "Milkshake etkinliği, katılımcılara lezzetli bir mola verme fırsatı sunar. İş yoğunluğundan uzaklaşarak, tatlı bir mola ile dinlenme ve rahatlama şansı elde ederler."
            },
            {
                title: 'Motivasyon ve Moral Artışı',
                text: "Katılımcıların lezzetli milkshake'lerle ödüllendirilmesi, moral ve motivasyonlarını artırır. Bu da çalışma verimliliğini olumlu yönde etkiler."
            },
            {
                title: 'Ekip Bağlarını Güçlendirme',
                text: "Ortak bir zevk olan milkshake'ler, katılımcılar arasında ortak bir nokta oluşturarak ekip bağlarını güçlendirir. Bu etkinlik, ekip üyelerinin birbirleriyle daha yakın ilişki kurmalarını sağlar."
            },
            {
                title: 'Keyifli Deneyim',
                text: "Milkshake etkinliği, katılımcılara keyifli ve eğlenceli bir deneyim yaşatır. Lezzetli içeceklerin tadını çıkarırken birbirleriyle sohbet etme ve güzel zaman geçirme fırsatı bulurlar."
            },
            {
                title: 'Ekip Bağlarını Güçlendirme',
                text: "Şirketin çalışanlarına bu tür keyifli etkinlikler düzenlemesi, şirket kültürünü oluşturan ve çalışanların memnuniyetini artıran unsurlardan biridir. Bu da şirketin çalışanlar arasında daha cazip bir iş yeri olmasına yardımcı olur."
            },
        ],
        texts: [
            "Sıcak yaz günlerinde serinlemek için iş yerinde bir milkshake partisi düzenlemeye ne dersiniz? Farklı meyve ve lezzet seçenekleriyle zenginleştirilmiş milkshakeler, çalışanlarınızın tatlı bir mola verip birbirleriyle keyifli vakit geçirmesini sağlayacak. Renkli pipetlerle süslenmiş bu lezzetli içecekler, hem enerji verici hem de eğlenceli bir atmosfer sunuyor.",
        ],
    },
    {
        id: 4,
        title: 'Enerji Festivali',
        topTitle: 'Sağlık ve Keyif Dolu Vitamin Şöleni!',
        gainTitle: "Vitamin Bar Etkinliği Bize Ne Sağlayacak?",

        img: 'https://www.eglenceorganizasyonu.com.tr/editor/images/vitamin-bar-organizasyonu.jpg',
        imgs: [
            "/img/event-detail/vitamin1.png",
        ],
        gains: [
            {
                title: 'Sağlıklı Beslenme Farkındalığı',
                text: "Etkinlik, çeşitli meyve, sebze ve vitamin takviyeleriyle zenginleştirilmiş içecekler ve atıştırmalıklar sunarak katılımcılara sağlıklı beslenme konusunda farkındalık kazandırır. Taze meyve ve sebzelerin yanı sıra, vitamin takviyeleriyle güçlendirilmiş smoothie'ler ve doğal içeceklerle bedenlerine ihtiyaç duyduğu besin öğelerini sağlamaları teşvik edilir."
            },
            {
                title: 'Enerji Artışı',
                text: "Vitamin bar, katılımcılara doğal enerji kaynakları sunar ve etkinlik boyunca enerjik ve canlı kalmalarını sağlar. Özellikle yoğun çalışma saatleri arasında enerji düşüklüğü yaşayanlar için, taze meyve suları, smoothie'ler ve sağlıklı atıştırmalıklar, doğal bir enerji artışı sağlar ve katılımcıların daha verimli çalışmalarını destekler."
            },
            {
                title: 'Sağlıklı Yaşam Biçimi Teşviki',
                text: "Vitamin bar etkinliği, katılımcıların sağlıklı yaşam biçimi ve beslenme alışkanlıkları konusunda teşvik eder ve bilinçlendirir. Sağlıklı içecekler ve atıştırmalıkların sunulduğu bu etkinlik, katılımcıları sağlıklı alternatifler hakkında düşünmeye ve tercihlerini daha bilinçli bir şekilde yapmaya teşvik eder. Ayrıca, sağlık uzmanlarının sağlıklı yaşam hakkında bilgi verdiği ve katılımcıların sağlık hedeflerine ulaşmalarına yardımcı olacak ipuçları sunduğu bir ortam sağlar."
            },

        ],
        texts: [
            "Sağlıklı yaşam ve enerjik bir iş ortamı için bir araya geliyoruz! Bu etkinlikte, vitamin barımızda çeşitli meyve suyu ve smoothie seçenekleriyle dolu bir şölen sunuyoruz. İş yerinizde enerjinizi yükseltecek ve sağlığınıza katkı sağlayacak bu özel etkinliği kaçırmayın!",
            "Bu etkinlik, sağlıklı yaşam ve beslenme konularında katılımcıların bilgi düzeyini artırırken, aynı zamanda sağlıklı alışkanlıkların benimsenmesine ve sürdürülmesine yardımcı olur."
        ],
    },
    {
        id: 5,
        title: 'Özel Gün Kutlamaları',
        topTitle: 'Pozitif Enerji ve Keyif Dolu Anlar!',
        gainTitle: "Özel Gün Etkinliklerinden Elde Edilebilecek Kazanımlar",

        img: '/img/event-detail/ozelgun.png',
        imgs: [
            "/img/event-detail/ozelgun1.png",
        ],
        gains: [
            {
                title: 'Çalışan Morali ve Motivasyonu',
                text: "Özel gün kutlamaları, çalışanların moralini ve motivasyonunu artırır. Çalışanlar, işverenlerinin onların başarılarını ve katkılarını takdir ettiğini hissederler. Bu da işyerinde daha mutlu ve işe daha bağlı çalışanlar demektir."
            },
            {
                title: 'Ekip İçi İletişimi Geliştirme',
                text: "Özel gün kutlamaları, çalışanların bir araya gelmesini ve birbirleriyle etkileşimde bulunmasını sağlar. Bu tür etkinlikler, departmanlar arası iletişimi artırır ve farklı ekipler arasında işbirliğini teşvik eder."
            },
            {
                title: 'Takım Ruhunu Güçlendirme',
                text: "Özel gün kutlamaları, takım ruhunu güçlendirir. Çalışanlar, bir araya gelerek takım olarak bir şeyler başardıklarını ve başarının kutlanması gerektiğini hissederler. Bu da işbirliği ve takım çalışmasını teşvik eder."
            },
            {
                title: 'Şirket Kültürünü Geliştirme',
                text: "Özel gün kutlamaları, şirket kültürünü oluşturmanın ve güçlendirmenin bir yolu olarak hizmet eder. Belirli günleri kutlamak, şirketin değerlerini ve amaçlarını vurgular ve çalışanlara şirketin bir parçası olmanın ne anlama geldiğini gösterir."
            },
            {
                title: 'Çalışan Sadakatini ve Memnuniyetini Artırma',
                text: "Özel gün kutlamaları, çalışanların şirkete olan sadakatini ve memnuniyetini artırır. Çalışanlar, işverenlerinin onların ihtiyaçlarını ve isteklerini önemsediğini gördükçe şirkete daha bağlı hale gelirler."
            },
        ],
        texts: [
            "Şirket olarak özel günleri kutlamak ve birlikte keyifli zamanlar geçirmek için buradayız! Her ay düzenlediğimiz özel gün kutlamalarıyla, sizlere unutulmaz deneyimler sunuyoruz ve bir araya gelmenin tadını çıkarıyoruz.",
            "Özel gün kutlamalarımız, farklı konseptler etrafında şekillenir ve herkesin katılımını bekler. Sizlere eğlenceli ve keyifli bir ortam sunmak için elimizden gelenin en iyisini yapıyoruz."
        ],
    },
    {
        id: 6,
        title: 'Kurumsal Piknik Şenlikleri',
        topTitle: 'Doğal Harikalar ve Müthiş Anlar!',
        gainTitle: "Kurumsal Piknik Etkinliğinin Şirketimiz İçin Önemi ve Getirileri",

        img: '/img/event-detail/piknik.png',
        imgs: [
            "/img/event-detail/piknik1.png",
        ],
        gains: [
            {
                title: 'Yaratıcılığı Teşvik',
                text: "Doğanın ortasında gerçekleşen piknik etkinliği, katılımcıların yaratıcı düşünme süreçlerini destekler. Açık havada yapılan etkinlikler, yeni fikirlerin ortaya çıkmasına ve yenilikçi yaklaşımların geliştirilmesine olanak tanır."
            },
            {
                title: 'Stres Azaltma ve Rahatlama',
                text: "Doğanın sessiz ve huzurlu atmosferi, katılımcıların stres seviyelerini düşürür ve rahatlamalarını sağlar. Piknik etkinliği, iş yoğunluğundan uzaklaşarak zihinsel ve duygusal olarak yenilenmelerini sağlar."
            },
            {
                title: 'Takım Bağlarını Derinleştirme',
                text: "Piknik ortamı, daha samimi ve rahat bir atmosfer sağlayarak ekip üyelerinin birbirleriyle daha yakın ilişkiler kurmasına olanak tanır. Bu da takım bağlarının derinleşmesine ve güçlenmesine katkı sağlar."
            },
            {
                title: 'Doğal Rekreatif Aktiviteler',
                text: "Piknik alanında gerçekleştirilebilecek doğal rekreatif aktiviteler, ekip üyelerinin birlikte eğlenmelerini ve rekabetçi ruhlarını canlandırmalarını sağlar. Bu tür aktiviteler, takım ruhunu güçlendirir ve ekip içi iletişimi artırır."
            },
            {
                title: 'Yenilikçi İşbirliği',
                text: "Piknik etkinliği, geleneksel iş ortamının dışında gerçekleştiği için farklı perspektiflerin ve düşüncelerin paylaşılmasını teşvik eder. Bu da yenilikçi işbirlikleri ve projelerin doğmasına olanak tanır."
            },
            {
                title: 'Sağlıklı Yaşam Biçimi Teşviki',
                text: "Piknik, doğal ve sağlıklı yiyeceklerin tüketilmesini teşvik eder. Ayrıca, açık havada yapılan fiziksel aktiviteler katılımcıların daha sağlıklı bir yaşam tarzı benimsemelerine yardımcı olur. Bu da şirket içinde sağlıklı yaşam bilincinin artmasına katkı sağlar."
            },
        ],
        texts: [
            "Şirketimiz olarak doğayla iç içe, eğlenceli bir gün geçirmek için heyecanla bir araya geliyoruz! Kurumsal piknik organizasyonumuzda, nefes kesen doğal manzaralar eşliğinde iş arkadaşlarımızla unutulmaz bir deneyim yaşamayı hedefliyoruz.",
            "Bu özel gün boyunca, piknik alanımızda çeşitli etkinlikler ve aktiviteler düzenleyerek birlikte eğlenme fırsatı bulacağız. Doğal piknik oyunları, takım binalama aktiviteleri ve rekabetçi oyunlarla dolu bir program hazırladık. Kimimizin yarıştığı, kimimizin desteklediği keyifli anlarla dolu bu etkinliklerde, ekip ruhunu daha da güçlendireceğiz.",
            "Lezzetli açık büfe yemekler ve atıştırmalıklarla karşılanacağımız piknik alanımızda, doğanın ve birbirimizin tadını çıkarırken, iş arkadaşlarımızla daha yakın bir bağ kurma fırsatı bulacağız. Herkesin katılımıyla oluşacak samimi sohbetler, yeni dostlukların ve iş birliklerinin temelini oluşturacak.",
            "Bu özel günü kahkahalarla dolu, unutulmaz anılarla süsleyerek geçireceğiz. Kurumsal piknik organizasyonumuzda, doğayla iç içe olmanın ve iş arkadaşlarımızla birlikte keyifli vakit geçirmenin tadını çıkaracağız!"
        ],
    },
    {
        id: 7,
        title: ' Kurumsal Kahkaha Yogası',
        topTitle: 'İş Yerinde Stresi Azaltmanın Yeni Yolu!',
        gainTitle: "Kurumsal Kahkaha Yogasının Faydaları",

        img: '/img/event-detail/kahkaha.png',
        imgs: [
            "/img/event-detail/kahkaha1.png",
        ],
        gains: [
            {
                title: 'Güldükçe Rahatlama',
                text: "Kahkaha yoga, katılımcıların kahkaha sesleri eşliğinde gevşemesini sağlayan özel bir yoga tekniğidir. Bu egzersizler sırasında, vücudu rahatlatan nefes teknikleri ve basit yoga pozları kullanılır. Bu sayede, çalışanlarınızın stres seviyeleri düşer ve daha huzurlu bir zihin haline geçiş yaparlar."
            },
            {
                title: 'Ekip Bağlarını Güçlendirme',
                text: "Kurumsal Kahkaha Yoga Etkinlikleri, ekip ruhunu güçlendirmek için mükemmel bir ortam sağlar. Çalışanlarınız bir araya gelerek birlikte gülmeyi öğrenir ve bu deneyimi paylaşırken, aralarındaki iletişimi ve bağları güçlendirirler. Bu etkinlikler, iş arkadaşlarının birbirlerine destek olma ve birlikte çalışma becerilerini geliştirmelerine yardımcı olur."
            },
            {
                title: 'Sağlıklı ve Pozitif İş Ortamı',
                text: "Kahkaha yoga, sadece stresi azaltmakla kalmaz, aynı zamanda pozitif bir ruh halini de teşvik eder. Çalışanlarınızın gülmekten gelen mutluluk ve iyimserlikle dolmaları, iş yerinde daha sağlıklı ve pozitif bir ortamın oluşmasına katkı sağlar. Bu da iş verimliliğini artırır ve çalışan memnuniyetini yükseltir."
            },
            {
                title: 'Stres Azaltma ve Rahatlama',
                text: "Katılımcılar, kahkaha yogasıyla streslerini azaltır ve rahatlarlar, böylece daha mutlu ve dengeli bir ruh haline sahip olurlar."
            },
        ],
        texts: [
            "Şirketinizin çalışanlarının ruhunu yenilemek ve iş stresini azaltmak için mükemmel bir fırsat: Kurumsal Kahkaha Yoga Etkinliği! Bu etkinliklerde, çalışanlarınız bir araya gelerek kahkaha yoga egzersizleri yapacaklar ve gülmekten gelen rahatlamayı deneyimleyeceklerdir.",
            "Kurumsal Kahkaha Yoga Etkinlikleri, şirketinizin çalışanlarına sağlıklı bir şekilde stres atma ve ekip ruhunu güçlendirme fırsatı sunar. Bu etkinlikler, işyerinde daha mutlu, daha sağlıklı ve daha verimli bir atmosfer oluşturmanıza yardımcı olabilir",
        ],
    },
    {
        id: 8,
        title: 'Drink and Draw Etkinliği',
        topTitle: 'Eğlenceli ve Yaratıcı Bir Deneyim!',
        gainTitle: "Drink and Draw Etkinliğinden Elde Edilecek Kazanımlar",
        img: '/img/event-detail/drink-draw.png',
        imgs: [
            "/img/event-detail/drinkdraw1.png",
        ],
        gains: [
            {
                title: 'Yaratıcılığı Teşvik',
                text: "Katılımcılar, içeceklerini alırken aynı zamanda çizim yaparak yaratıcılıklarını geliştirirler. Bu süreç, yeni fikirlerin ortaya çıkmasını teşvik eder ve katılımcıların sanatsal yeteneklerini keşfetmelerine olanak sağlar."
            },
            {
                title: 'Topluluk ve İletişim',
                text: "Etkinlik, çeşitli insanların bir araya gelerek birbirleriyle tanışmalarını ve iletişim kurmalarını sağlar. Bu, şirket içindeki sosyal bağların güçlenmesine katkıda bulunur ve çalışanlar arasında daha sıkı bir dayanışma oluşturur."
            },
            {
                title: 'Gelişim Fırsatı',
                text: "Katılımcılar, birbirlerinden ve hatta etkinliği düzenleyenlerden geri bildirim alarak sanat becerilerini geliştirme fırsatı bulabilirler. Bu geri bildirimler, katılımcıların kendilerini geliştirmelerine ve daha iyi bir sanatçı olmalarına yardımcı olur."
            },
            {
                title: 'Sanat Bilgisi ve Deneyimi Paylaşımı',
                text: "Etkinlik sırasında, katılımcılar farklı sanat tarzları ve teknikleri hakkında bilgi alışverişi yaparlar. Ayrıca, deneyimlerini paylaşarak birbirlerinden öğrenirler ve sanat alanında yeni perspektifler kazanırlar."
            },
            {
                title: 'Kendini İfade Etme',
                text: "Çizim yapmak, katılımcıların duygularını ve düşüncelerini ifade etmelerine olanak tanır. Herkesin kendi tarzında çizim yapabilmesi, kişisel gelişimlerine katkıda bulunur ve özgüvenlerini artırır. Bu, hem sanatsal hem de kişisel olarak tatmin edici bir deneyim sunar."
            },
        ],
        texts: [
            "Yaratıcı yanlarını keşfetmek isteyen çalışanlarınız için bir 'Drink and Draw' etkinliği düzenleyin. Rahatlatıcı bir atmosferde bir araya gelip çizim yapmanın keyfini çıkarın. Hem sanatsal yeteneklerini geliştirme fırsatı bulacaklar hem de birbirleriyle bağ kuracaklar.",
        ],
    },
    {
        id: 9,
        title: 'Happy Hour',
        topTitle: 'Mutluluk Rüzgarı!',
        img: '/img/event-detail/drink-draw.png',
        gainTitle: '"Mutluluk Rüzgarı" gibi bir etkinlik, işyerinde birçok fayda sağlayabilir',
        imgs: [
            "/img/event-detail/happy1.png",
        ],
        gains: [
            {
                title: 'Takım Bağlarını Güçlendirme',
                text: "İş arkadaşları arasında samimiyeti artırarak, daha güçlü ve daha etkili bir takım oluşturabilir. İnsanlar iş dışında birbirlerini daha iyi tanıdıkça, iş birliği ve iletişimleri genellikle daha iyi hale gelir."
            },
            {
                title: 'Motivasyonu Artırma',
                text: "Çalışanların bir araya gelip stres atabilecekleri ve eğlenebilecekleri bir ortam sağlamak, motivasyonu artırabilir. Bu tür etkinlikler, çalışanların işlerine daha motive olmalarına ve daha iyi performans göstermelerine yardımcı olabilir."
            },
            {
                title: 'İletişimi Geliştirme',
                text: "İş dışı sosyal etkinlikler, çalışanlar arasındaki iletişimi geliştirir. Daha rahat bir ortamda, insanlar genellikle daha açık ve samimi olurlar, bu da iş yerinde iletişimi artırabilir."
            },
            {
                title: 'Stres Azaltma',
                text: "İş yerindeki stresi azaltmanın ve rahatlamalarına yardımcı olmanın bir yolu olarak 'Mutluluk Rüzgarı', çalışanların zihinsel ve duygusal olarak yeniden şarj olmalarına olanak tanır."
            },
            {
                title: 'Ekip Ruhunu Güçlendirme',
                text: "Ekip üyelerinin birbirlerini daha iyi tanıması ve aralarındaki ilişkileri güçlendirmesi, ekip ruhunu güçlendirir. Bu da işbirliği ve iş verimliliği açısından önemlidir."
            },
            {
                title: 'Çalışan Memnuniyetini Artırma',
                text: "Çalışanların iş yerinde keyif aldıkları bir ortamda çalışmaları, genel çalışan memnuniyetini artırabilir. Mutlu ve tatmin olan çalışanlar, genellikle daha sadık, daha üretken ve daha uzun süreli olurlar."
            },
        ],
        texts: [
            "Haftanın yorgunluğunu atmak ve birlikte keyifli vakit geçirmek için tüm çalışanlarımızı mutluluk saatinde bir araya bekliyoruz! Soğuk içecekler ve atıştırmalıklar eşliğinde, iş arkadaşlarımızla stres atmanın ve eğlenmenin tadını çıkaralım.",
            "Bu etkinlik aynı zamanda takım ruhunu güçlendirmek, yeni insanlarla tanışmak ve iş dışında birbirimizi daha yakından tanımak için de harika bir fırsat. Unutmayın, güçlü bir takım olmanın temeli samimiyet ve dayanışmadır.",
            "Bu gibi etkinlikler, işyerindeki atmosferi iyileştirebilir ve çalışanların moralini yükseltebilir, bu da uzun vadede şirketin başarısına olumlu katkıda bulunabilir.",
        ],
    },


]
function EventDetail() {
    const location = useLocation()
    const [activeEvent, setActiveEvent] = useState(undefined)

    useEffect(() => {


        const filteredArray = EVENTS.filter((item) => item.id === location.state.id)
        setActiveEvent(filteredArray[0])
        console.log(filteredArray)
    }, [])



    return (
        <div class="main">

            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('/img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 class="text-white mb-0" id="news-1">{activeEvent && activeEvent.topTitle}</h1>
                                <div class="custom-breadcrumb">
                                    {/* <ol class="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php" title="anasayfa">Anasayfa</a></li>
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php#news" title="haberler">Haberler</a></li>
                                        <li class="list-inline-item breadcrumb-item active" title="ming">Ming</li>
                                    </ol> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="row align-items-center justify-content-between p-5">
                <div class="col-md-6">
                    <div class="about-content-right resize">
                        <img style={{ borderRadius: 20 }} src={activeEvent && activeEvent.img} width="870" height="856" alt="Kurumsal bağ ve hafıza gelişimi / Oyunlaştırma ile iş süreçleri iyileştirilmesi" class="img-fluid" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="about-content-left section-heading pd-t font-card">
                        <h1 style={{ paddingBottom: 20 }}> {activeEvent && activeEvent.title}
                        </h1>

                        {
                            activeEvent && activeEvent.texts.map((text) => (
                                <p style={{ fontSize: 20 }}>
                                    {text}
                                </p>
                            ))
                        }



                    </div>
                </div>

            </div>

            <div class="row align-items-center justify-content-between p-5">

                <div class="col-md-6">

                    <h1 className='text-center'>{activeEvent && activeEvent.gainTitle}</h1>



                    <div className='p-5'>
                        {
                            activeEvent && activeEvent.gains.map((gain) => (
                                <div >
                                    <h3>
                                        {gain.title}
                                    </h3>
                                    <p>
                                        {gain.text}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="about-content-right resize">
                        <img style={{ borderRadius: 20 }} src={activeEvent && activeEvent.imgs[0]} width="870" height="856" alt="Kurumsal bağ ve hafıza gelişimi / Oyunlaştırma ile iş süreçleri iyileştirilmesi" class="img-fluid" />
                    </div>
                </div>

            </div>
            {/* 
            <div class="row  p-5" style={{ justifyContent: 'space-evenly' }}>
                {
                    activeEvent && activeEvent.imgs.map((img) => (
                        <div class="col-md-5">
                            <div class="about-content-right resize">
                                <img style={{ borderRadius: 20 }} src={img} height="856" alt="Kurumsal bağ ve hafıza gelişimi / Oyunlaştırma ile iş süreçleri iyileştirilmesi" class="img-fluid" />
                            </div>
                        </div>
                    ))
                }

            </div> */}


            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-preview">
                                    {/* <!--<img src=""  alt="article" class="img-fluid"/>--> */}
                                </div>
                                <div class="post-wrapper">

                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EventDetail