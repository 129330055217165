import React from 'react'

function Cookie() {
    return (
        <div class="main">

            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 id="cookie" style={{ color: "white" }} class="post-title">Ming Çerez Politikası</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-wrapper">
                                    <div class="post-content">
                                        <p>
                                            <strong>Çerez (Cookie) nedir?</strong>
                                        </p> <p>Size daha iyi, hızlı ve güvenli bir deneyim sağlamak için çeşitli çerezler kullanıyoruz. Çerezler, ziyaret ettiğiniz web sitesi ve/veya mobil uygulama tarafından tarayıcılar aracılığıyla cihazınızda veya ağ sunucusuna depolanan küçük veri dosyalarıdır.</p> <p>
                                            <strong>Çerezler neden kullanılmaktadır?</strong>
                                        </p><p>Çerezler, türlerine göre ayrılmakta olup MİNG tarafından yalnızca yapacağınız tercihlerle sınırlı olarak çerez kullanılmaktadır. Bunlar web sitesi veya uygulamanın çalışması için zorunlu olan çerezler veya daha önceki ziyaretlerinizde yapmış olduğunuz tercihlerin hatırlanmasını sağlayarak daha kişiselleştirilmiş bir deneyim sunmak amacıyla kullanılan çerezler olabilir. Çerezler, web sitesinin veya uygulamanın işlevselliğini ve performansını arttırarak kullanıcı deneyimini geliştirmek, iyileştirmek, kullanıcılara yeni özellikler sunmak ve sunulan özellikleri kullanıcı tercihlerine göre kişiselleştirmek, aynı zamanda web sitesinin hukuki ve ticari güvenliğinin teminini sağlamak amacıyla kullanılmaktadır. MİNG olarak çerez kullanımındaki temel amacımız sizlere daha eşsiz, kişisel ve hızlı bir deneyim sunabilmektir.</p> <p>
                                            <strong>Çerez Türleri ve Kullanım Amaçları</strong>
                                        </p> <p>Çerezler, aşağıda belirtilen üç ana kritere göre sınıflandırılmakta olup, kişisel tercihlerinizi daha kolay yapabilmeniz adına her kategorinin amacı açıklanmaktadır.</p> <table>
                                            <tbody >
                                                <tr>
                                                    <td>Kullanım Amaçlarına Göre</td>
                                                    <td>Saklama Sürelerine Göre</td>
                                                    <td>Kaynaklarına Göre</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li>Zorunlu</li>
                                                            <li>İşlevsel</li>
                                                            <li>Performans</li>
                                                            <li>Reklam/Pazarlama</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <p>&nbsp;</p>
                                                        <ul>
                                                            <li>Oturum</li>
                                                            <li>Kalıcı</li>
                                                        </ul>
                                                        <p>&nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>&nbsp;</p>
                                                        <ul>
                                                            <li>Birinci taraf</li>
                                                            <li>üçüncü taraf</li>
                                                        </ul>
                                                        <p>&nbsp;</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table> <p>Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezlerin ilk kategorisi ise temel olarak dört grupta toplanmaktadır.</p> <table>
                                            <tbody style={{ width: "700px", height: "400px" }}>
                                                <tr>
                                                    <td>Çerez Türleri</td>
                                                    <td>Kullanım Amaçları</td>
                                                </tr>
                                                <tr>
                                                    <td>Oturum Çerezleri</td>
                                                    <td>İnternet sayfaları arasında bilgi taşınması ve kullanıcı tarafından girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya olanak sağlayan çerezlerdir ve internet sitesine ait fonksiyonların düzgün bir şekilde işleyebilmesi için zorunludur.</td>
                                                </tr>
                                                <tr>
                                                    <td>Performans Çerezleri</td>
                                                    <td>Sayfaların ziyaret edilme frekansı, olası hata iletileri, kullanıcıların ilgili sayfada harcadıkları toplam zaman ile birlikte siteyi kullanım desenleri konularında bilgi toplayan çerezlerdir ve internet sitesinin performansını arttırma amacıyla kullanılmaktadır.</td>
                                                </tr>
                                                <tr>
                                                    <td>Fonksiyonel Çerezler</td>
                                                    <td>Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve internet sitesi kapsamında kullanıcılara gelişmiş Internet özellikleri sağlanmasını hedeflemektedir.</td>
                                                </tr>
                                                <tr>
                                                    <td>Reklam Ve üçüncü Taraf Çerezleri</td>
                                                    <td>üçüncü parti tedarikçilere ait çerezlerdir ve internet sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin yapılmasına olanak sağlamaktadır.</td>
                                                </tr>
                                            </tbody>
                                        </table> <p>MİNG tarafından, tercihlerinize göre kullanılacak olan çerezlere türlerine göre aşağıdaki amaçlarla sınırlı olarak Kişisel Verilerin Korunması Mevzuatına uygun şekilde kullanılmaktadır:</p> <ul> <li>Güvenlik amaçlı kullanımlar:&nbsp; MİNG sistemlerinin idaresi ve güvenliğinin sağlanabilmesi için bu sitedeki ve/veya uygulamadaki fonksiyonlardan yararlanmayı sağlayan veya düzensiz davranışları tespit eden çerezler kullanabilmektedir.</li> <li>İşlevselliğe yönelik kullanımlar:&nbsp; MİNG, sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve geçmiş seçimlerini hatırlatan çerezler kullanabilmektedir.</li> <li>Performansa yönelik kullanımlar:&nbsp; MİNG sistemlerinin performansının artırılması ve ölçülmesi amacıyla, gönderilen iletilerle olan etkileşimi ve kullanıcı davranışlarını değerlendiren ve analiz eden çerezler kullanabilmektedir.</li><li>Reklam amaçlı kullanımlar: MİNG kendine veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla, bu reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden çerezler kullanabilmektedir.</li></ul> <p>
                                            <strong>Birinci ve üçüncü Taraf çerezleri</strong>
                                        </p> <p>çerezler MİNG tarafından işletilen elektronik ticaret platformlarının web sitesinde, mobil uygulamasında ve mobil sitesinde kullanılmaktadır. Yerleştiren tarafa göre ise; &ldquo;MİNG çerezleri (first party cookie)&rdquo; ve "üçüncü taraf (third party cookie) çerezleri
                                            " kullanılmaktadır. MİNG&rsquo;in çerezleri, MİNG tarafından oluşturulurken, üçüncü taraf çerezleri ise iş birliği yaptığımız üçüncü taraf şirketler Google Analytics, Firebase, Firebase Crashlytics&nbsp;tarafından yönetilmektedir.</p> <p>
                                            <strong>Reklam Amaçlı Çerez Kullanımı</strong>
                                        </p> <p>MİNG, Google Adsense, Google ADS, Facebook, LinkedIn Insight Tag, Instagram reklam sistemi kullanmaktadır. Bu sistem Google tarafından içerik için AdSense reklamlarının görüntülendiği yayıncı web sitelerinde sunulan reklamlarda kullanılan DoubleClick DART çerezi içerir.</p> <p>üçüncü taraf satıcı olarak Google, sitemizde reklam yayınlamak için çerezlerden yararlanır. Bu çerezlerini kullanarak web sitesine, uygulamaya ya da diğer sitelere yaptığınız ziyaretlere göre ilginizi çekebilecek reklamlar sunar. Google, web sitemizi ziyaret ettiği zamanlarda reklam hizmeti vermek için üçüncü taraf reklam şirketlerini kullanmaktadır. Söz konusu şirketler, bu sitelere ve diğer web sitelerine yaptığınız ziyaretlerden elde ettikleri (adınız, adresiniz, e-posta adresiniz veya telefon numaranız dışındaki) bilgileri ilginizi çekecek ürün ve hizmetlerin reklamını size göstermek için kullanabilir. Bu uygulama hakkında bilgi edinmek için ve söz konusu bilgilerin bu şirketler tarafından kullanılmasını engellemek üzere reklam amaçlı Çerez kullanımını ayarlardan kapatabilirsiniz.</p> <p>MİNG web sitesi ve/veya uygulamasında aşağıda listelenmiş olan çerezler kullanılmaktadır:&nbsp;</p>
                                        <table border="1" style={{ width: "700px", height: "400px" }}>
                                            <tbody>
                                                <tr >
                                                    <td>Çerez İsmi</td>
                                                    <td>Çerez Servis Sağlayıcısı</td>
                                                    <td>Çerez Kategorileri</td>
                                                    <td>Çerez Saklama Süresi</td>
                                                </tr>
                                                <tr >
                                                    <td>Google Analytics</td>
                                                    <td>Google</td>
                                                    <td>İşlevsel</td>
                                                    <td>Kalıcı&nbsp;</td>
                                                </tr>
                                                <tr >
                                                    <td>Firebase&nbsp;</td>
                                                    <td>Google</td>
                                                    <td>İşlevsel&nbsp;</td>
                                                    <td>Kalıcı&nbsp;</td>
                                                </tr>
                                                <tr >
                                                    <td>Firebase Crashlytics</td>
                                                    <td>Google</td>
                                                    <td>Performans</td>
                                                    <td>Kalıcı&nbsp;</td>
                                                </tr>
                                                <tr >
                                                    <td>Facebook Pixel</td>
                                                    <td>Meta&nbsp;</td>
                                                    <td>Reklam/Pazarlama&nbsp;</td>
                                                    <td>Kalıcı&nbsp;</td>
                                                </tr>
                                                <tr >
                                                    <td>LinkedIn Insight Tag</td>
                                                    <td>Linkedin</td>
                                                    <td>Reklam/Pazarlama&nbsp;</td>
                                                    <td>Kalıcı&nbsp;</td>
                                                </tr>
                                                <tr >
                                                    <td>Google ADS</td>
                                                    <td>Google</td>
                                                    <td>Reklam/Pazarlama</td>
                                                    <td>Oturum&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Cookie